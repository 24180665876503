<template>
  <div>
    <Connect
      v-if="showSocialMediaDialog"
      :social-media-connections="socialMediaConnections"
      :content="selectedContentForPosting"
      :post-from-history-page="true"
      @close="closePostToFacebookDialog"
    />
    <v-tab-item :value="filterValue">
      <BlurredBackdrop :is-blurred="disabled">
        <template v-slot:info>
          <div>
            {{ $t('alerts.upselling.upgrade.0') }}
            <router-link
              to="/settings/contract"
              class="pointer white--text font-weight-bold"
            >
              {{ $t('alerts.upselling.upgrade.link') }}
            </router-link>
            {{ $t('alerts.upselling.upgrade.1') }}
          </div>
        </template>
        <v-timeline
          :dense="$vuetify.breakpoint.smAndDown"
        >
          <v-timeline-item
            v-for="content in savedContents"
            :key="content.id"
            class="v-timeline-item"
          >
            <template v-slot:icon>
              <v-avatar>
                <v-img
                  v-if="content.icon"
                  :src="content.icon"
                  class="timeline-avatar"
                  contain
                />
                <span
                  v-else
                  class="avatar-icon-download mdi mdi-download"
                />
              </v-avatar>
            </template>
            <span slot="opposite">
              <span class="createdAt">
                {{ $t('content-creator.created-on') }}:</span>
              <p class="createdAt-content">{{ content.createdAt }}</p>
            </span>
            <v-card class="elevation-2">
              <div class="d-md-flex flex-md-no-wrap justify-md-space-between">
                <div>
                  <v-card-title class="text-h5 px-2 px-sm-4 pb-0 pb-sm-4">
                    {{ ReadableKindType[content.kind] }}
                  </v-card-title>
                  <v-tooltip
                    content-class="tooltip-class"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-card-text class="pa-2 pa-sm-4">
                          {{ textTruncate(content.text, 150) }}
                        </v-card-text>
                      </span>
                    </template>

                    <span class="d-flex justify-space-between">
                      <v-alert
                        class="mt-5 alert"
                        icon="mdi-information-slab-circle"
                        type="info"
                        color="primary"
                      >
                        {{ content.text }}
                      </v-alert>
                    </span>
                  </v-tooltip>
                </div>
                <v-img
                  v-if="content.image"
                  class="ma-2 ma-sm-4 w-full"
                  :src="content.image"
                  max-width="175"
                />
              </div>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-text class="pt-0 px-2 px-sm-4 d-md-flex">
                  <div class="mr-md-2 d-md-flex align-center">
                    <span
                      v-if="content.postedOn?.length > 0"
                      class="mr-1"
                    >
                      {{ $t('content-creator.table.labels.posted-on') }}:
                    </span>
                    <v-tooltip
                      v-for="(postedOn, index) in content.postedOn"
                      :key="index"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <a
                          :href="content.socialMediaPosts.find(l => l.type === postedOn.split('P')[0].toUpperCase())?.permalink"
                          target="_blank"
                          @click="setTrackingEvent()"
                        >
                          <v-avatar
                            :size="$vuetify.breakpoint.smAndDown ? 20 : 25"
                            class="mr-1 mr-md-2 mb-1 mb-sm-0"
                            tile
                            v-on="on"
                          ><v-img :src="KindTypeIcon[postedOn]" />
                          </v-avatar>
                        </a>
                      </template>
                      <span class="mr-1 mr-sm-2">{{ KindName[postedOn] }}</span>
                    </v-tooltip>
                  </div>
                  <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

                  <span
                    class="d-md-flex justify-md-space-between"
                    :class="{ 'col-12 pa-0': $vuetify.breakpoint.smAndDown }"
                  >
                    <span
                      class="mr-sm-2 py-1"
                      :class="{ 'col-12 px-0': $vuetify.breakpoint.smAndDown }"
                    >
                      {{ $t('content-creator.table.labels.downloaded') }}:
                      <v-icon
                        v-if="content.downloaded"
                        size="25"
                        color="primary"
                      >
                        mdi-check-circle
                      </v-icon>

                      <v-avatar
                        v-if="!content.downloaded"
                        size="25"
                        tile
                      >
                        <v-img
                          :src="require('@/../public/icons/close.png')"
                          class="pointer"
                        />
                      </v-avatar>
                    </span>
                    <span class="mr-sm-2">
                      <v-btn
                        :class="{ 'col-12 mb-2': $vuetify.breakpoint.smAndDown }"
                        outlined
                        :disabled="disabled"
                        elevation="0"
                        color="primary"
                        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                        @click="setupPostToFacebookDialog(content)"
                      >
                        <v-icon
                          class="mr-2"
                          size="14"
                        >
                          mdi-share-variant
                        </v-icon>
                        {{ $t('buttons.content-creator.history.share') }}
                      </v-btn>
                    </span>
                    <span class="mr-2">
                      <DownloadPost
                        :class="{ 'col-12': $vuetify.breakpoint.smAndDown }"
                        :saved-content-id="content.id"
                        :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 8 + 'px' : ''}"
                        :btn-class="'download-post-btn-in-history'"
                        :text="content.text"
                        :image="content.image"
                        :image-source="content.imageSource"
                        :kind="content.kind"
                        :topic="content.slug"
                        :topic-id="content.topicId"
                        :landing-page-id="content.landingPageId"
                        :url="content.url"
                        :disabled="disabled"
                        @savedContent="$emit('savedContent')"
                      />
                    </span>
                  </span>
                </v-card-text>
              </div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </BlurredBackdrop>
    </v-tab-item>
  </div>
</template>

<script>
import { textTruncate } from '@/lib/truncate'
import { PostType } from '@/modules/contentCreator/creator/enums/PostType'
import { KindName, KindTypeIcon, ReadableKindType } from '@/modules/contentCreator/creator/enums/KindType'
import BlurredBackdrop from '@/components/BlurredBackdrop.vue'
import Connect from '@/modules/contentCreator/creator/SocialMedia/Connect.vue'
import GET_SOCIAL_MEDIA_CONNECTIONS from '@/modules/contentCreator/creator/queries/getSocialMediaConnections.gql'
import DownloadPost from './DownloadPost.vue'

export default {
  components: { Connect, DownloadPost, BlurredBackdrop },
  props: {
    savedContents: {
      type: Array,
      default: () => []
    },
    filterValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      KindName,
      KindTypeIcon,
      PostType,
      socialMediaConnections: null,
      showSocialMediaDialog: false,
      selectedContentForPosting: {}
    }
  },
  computed: {
    ReadableKindType () {
      return ReadableKindType
    }
  },
  methods: {
    setTrackingEvent () {
      this.$tracking.event('Content History,', this.$tracking.trackingEvents.CLICKED, 'Link-to-Post')
    },
    textTruncate,
    async setupPostToFacebookDialog (content) {
      this.$tracking.event('Content History,', this.$tracking.trackingEvents.CLICKED, 'Content-Repurpose')
      this.selectedContentForPosting = {
        id: content.id,
        image: content.image,
        imagePath: content.image,
        imageSource: content.imageSource,
        text: content.text,
        postedOn: content.postedOn,
        textKind: content.kind,
        topicId: content.topicId,
        slug: content.slug,
        downloaded: content.downloaded
      }
      this.openPostToFacebookDialog()
    },

    openPostToFacebookDialog () {
      this.showSocialMediaDialog = true
    },

    closePostToFacebookDialog () {
      this.showSocialMediaDialog = false
    }
  },
  apollo: {
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS
    }
  }
}
</script>

<style scoped>
.v-timeline-item {
  margin-left: 200px;
  margin-right: 200px;
}

@media (min-width: 962px) and (max-width: 1740px) {
  .v-timeline-item {
    margin-left: 60px;
    margin-right: 60px;
  }
}
@media (min-width: 0) and (max-width: 1540px) {
  .v-timeline-item {
    margin-left: 0;
    margin-right: 0;
  }
}

.timeline-avatar {
  background-color: white;
}

.createdAt {
  color: #99A1B7;
  font-weight: 600;
}

.createdAt-content {
  color: #071437;
  font-weight: 500;
}

.table-row {
  border-bottom: 1px solid #e0e0e0;
}

.pointer {
  cursor: pointer;
}

.tooltip-class {
  background-color: transparent;
  max-width: 500px;
}

@media (max-width: 600px) {
  .tooltip-class {
    max-width: 100%;
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}

.th-downloaded {
  width: 50px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  padding: 0 8px;
  font-size: 10px;
}

.avatar-icon-download {
  color: white;
  font-size: 22px;
}
::v-deep .v-timeline-item__dot {
  width: 48px;
  height: 48px;
}

::v-deep .v-timeline-item__dot .v-timeline-item__inner-dot {
  margin: 0;
  width: 48px;
  height: 48px;
}
</style>
