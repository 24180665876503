<template>
  <v-card
    :style="cssProps"
    class="mx-auto"
    max-width="600"
    elevation="0"
  >
    <template v-if="isConnectingFinished">
      <v-card-title class="px-0 py-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <div class="d-flex justify-space-between toolbar">
            <v-toolbar-title class="my-auto">
              {{ $t('social-media.post') }}
            </v-toolbar-title>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text
        v-if="hasAvailablePages"
        class="px-0 py-0"
      >
        <p class="font-weight-bold px-5 py-5 mb-3">
          {{ $t('social-media.post-posted-on-the-following-platforms') }}
        </p>
        <v-card-text class="py-0">
          <v-row
            dense
          >
            <v-col
              v-for="page in pages"
              :key="page.id"
              cols="12"
              class="d-flex justify-center"
            >
              <v-card
                v-if="!hasActivePage || (hasActivePage && page.isActive)"
                class="pointer card-item mb-2"
                outlined
                :style="{ 'border': page.selected ? pageSelected : ''}"
                @click="selectSocialMediaPage(page)"
              >
                <v-list-item>
                  <v-list-item-avatar
                    tile
                    size="30"
                    class="my-2"
                  >
                    <v-img :src="KindTypeIcon[page.type]" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ page.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card-text>
      <v-card-text
        v-if="linkedinPagesNotAvailable"
        class="py-0 my-10 font-weight-bold"
      >
        {{ $t('alerts.social-media.get-linkedin-pages.info') }}
      </v-card-text>
      <v-card-text
        v-if="(facebookPagesNotAvailable && instagramPagesNotAvailable)"
        class="py-0 my-10 font-weight-bold"
      >
        {{ $t('alerts.social-media.get-facebook-pages.info') }}
        <a
          href="https://www.facebook.com/business/help/1199464373557428?id=418112142508425"
          target="_blank"
          rel="noopener noreferrer"
        >{{ $t('alerts.social-media.get-facebook-pages.linkText') }}</a>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between mt-md-5">
        <v-btn
          :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
          text
          elevation="0"
          @click="back"
        >
          {{ $t('buttons.social-media.link-additional-accounts') }}
        </v-btn>
        <v-btn
          :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
          color="primary"
          elevation="0"
          text
          :disabled="!hasSelectedPage || pages.length === 0"
          @click="generatePost"
        >
          {{ $t('social-media.post-now') }}
        </v-btn>
      </v-card-actions>
    </template>

    <v-card
      v-if="isConnecting || isPostedToSocialMedia"
      class="mx-auto"
      max-width="600"
    >
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <div class="toolbar d-flex justify-space-between">
            <v-toolbar-title class="my-auto">
              {{ $t('social-media.post') }}
            </v-toolbar-title>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <ProgressCircular v-if="!isPostedToSocialMedia" />
        <v-img
          v-if="isPostedToSocialMedia"
          class="mx-auto my-10"
          :src="require('@/../public/img/mark.png')"
          width="50"
          contain
        />
        <v-card-title class="d-flex justify-center px-10  pt-0">
          <span
            v-if="isConnecting"
            class="text-center newline"
          >
            {{ $t('alerts.social-media.post-to-social-network.info', { platform: readablePlatformName, socialMedia: socialMediaName }) }}<br>
          </span>
          <span
            v-if="isPostedToSocialMedia"
            class="text-center"
          >
            {{ $t('alerts.social-media.post-to-social-network.success.0') }}<br>
            {{ $t('alerts.social-media.post-to-social-network.success.1') }}
            <span
              class="redirect-route pointer"
              @click="redirectRoute()"
            >
              {{ $t('alerts.social-media.post-to-social-network.success-link') }}
            </span>
            {{ $t('alerts.social-media.post-to-social-network.success.2') }}
          </span>
          <span class="text-center" />
        </v-card-title>
      </v-card-text>
      <v-card-actions
        v-if="isPostedToSocialMedia || isConnectingFinished"
        class="d-flex justify-start mt-5"
      >
        <v-btn
          :style="{ 'font-size': $vuetify.breakpoint.smAndDown ? 12 + 'px' : ''}"
          elevation="0"
          text
          @click="closeDialog"
        >
          {{ $t('buttons.social-media.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>
<script>

import KindType, { KindName, KindTypeIcon } from '@/modules/contentCreator/creator/enums/KindType'
import POST_TO_SOCIAL_MEDIA from '@/modules/contentCreator/creator/queries/postToSocialMedia.gql'
import GET_SOCIAL_MEDIA_CONNECTIONS from '@/modules/contentCreator/creator/queries/getSocialMediaConnections.gql'
import SAVE_CONTENT from '../queries/saveContent.gql'

import ProgressCircular from '@/components/ProgressCircular.vue'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import ADD_FACEBOOK_PAGE_TO_USER from '@/modules/contentCreator/creator/queries/addFacebookPageToUser.gql'
import ADD_LINKEDIN_PAGE_TO_USER from '@/modules/contentCreator/creator/queries/addLinkedinOrganizationToUser.gql'
import {
  mapGraphQLErrors
} from '@/modules/contentCreator/creator/lib/facebookErrorsMapper'
import trackingEvents from '@/lib/trackingEvents'
import { FilterType } from '@/modules/contentCreator/creator/enums/FilterType'
import branding from '@/mixins/branding'

export default {
  components: { ProgressCircular },
  mixins: [branding],
  props: {
    activeTab: {
      type: String,
      default: FilterType.ALL
    },
    socialMediaPages: {
      type: Object,
      default: () => {}
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      state: 'connected',
      facebookPages: this.socialMediaPages?.facebook ?? [],
      instagramPages: this.socialMediaPages?.instagram ?? [],
      linkedinPages: this.socialMediaPages?.linkedin ?? [],
      postType: null,
      postData: []
    }
  },
  computed: {
    cssProps () {
      return {
        '--orange': this.$vuetify.theme.themes.light.markero.orange
      }
    },
    hasAvailablePages () {
      return ((this.isContentKindFacebookPost || this.isContentKindInstagramPost) &&
          (this.facebookPages?.length > 0 || this.instagramPages?.length > 0)) ||
        (this.isContentKindLinkedinPost && this.linkedinPages?.length > 0)
    },
    linkedinPagesNotAvailable () {
      return this.isContentKindLinkedinPost && this.linkedinPages?.length === 0
    },
    facebookPagesNotAvailable () {
      return this.isContentKindFacebookPost && this.facebookPages?.length === 0
    },
    instagramPagesNotAvailable () {
      return this.isContentKindInstagramPost && this.instagramPages?.length === 0
    },

    pages () {
      if (this.isContentKindFacebookPost) {
        return [...this.facebookPages, ...this.instagramPages, ...this.linkedinPages]
      } else if (this.isContentKindInstagramPost) {
        return [...this.instagramPages, ...this.facebookPages, ...this.linkedinPages]
      } else {
        return [...this.linkedinPages, ...this.facebookPages, ...this.instagramPages]
      }
    },
    pageSelected () {
      return `1px solid ${this.$vuetify.theme.themes.light.primary}`
    },
    isConnecting () {
      return this.state === 'connecting'
    },
    isConnectingFinished () {
      return this.state === 'connected'
    },
    isPostedToSocialMedia () {
      return this.state === 'posted'
    },
    socialMediaName () {
      return KindName[this.postType]
    },
    KindTypeIcon () {
      return KindTypeIcon
    },
    hasSelectedPage () {
      return this.pages?.some((page) => page.selected)
    },
    hasActivePage () {
      return this.pages?.some((page) => page.isActive)
    },
    isContentKindFacebookPost () {
      return this.content.textKind === KindType.facebookPost
    },
    isContentKindInstagramPost () {
      return this.content.textKind === KindType.instagramPost
    },
    isContentKindLinkedinPost () {
      return this.content.textKind === KindType.linkedinPost
    }
  },
  methods: {
    selectSocialMediaPage (page) {
      let targetPages = this.getTargetPagesArray(page.type)

      if (targetPages) {
        targetPages = targetPages.map(item => ({
          ...item,
          selected: item.id === page.id ? !item.selected : item.selected
        }))

        this.updateSocialMediaPages(page.type, targetPages)
      }
    },

    getTargetPagesArray (pageType) {
      if (pageType === KindType.facebookPost) {
        return this.facebookPages
      } else if (pageType === KindType.instagramPost) {
        return this.instagramPages
      } else if (pageType === KindType.linkedinPost) {
        return this.linkedinPages
      }
      return null
    },

    updateSocialMediaPages (pageType, pages) {
      if (pageType === KindType.facebookPost) {
        this.facebookPages = pages
      } else if (pageType === KindType.instagramPost) {
        this.instagramPages = pages
      } else if (pageType === KindType.linkedinPost) {
        this.linkedinPages = pages
      }
    },

    async generatePost () {
      if (!this.content.image) {
        showSnackbarMessage('error', this.$t('alerts.social-media.errors.facebook-post-image-missing'))
        return
      }

      const pages = this.pages.filter((page) => page.selected)
      const facebookPage = this.pages.find((page) => page.selected && page.type === KindType.facebookPost)
      const linkedinPage = this.pages.find((page) => page.selected && page.type === KindType.linkedinPost)
      if (facebookPage && !facebookPage.isActive) {
        await this.addFacebookPageToUser(facebookPage)
      }

      const hasAddedPageToUser = this.pages.some((page) => page.type === KindType.linkedinPost && page.addedPageToUser)
      if (linkedinPage && (!linkedinPage.isActive || !hasAddedPageToUser)) {
        await this.addLinkedinPageToUser(linkedinPage)
      }

      this.postData = []
      for (const page of pages) {
        this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CLICKED, 'Share ' + KindName[page.type])

        this.state = 'connecting'
        this.postType = page.type
        try {
          await this.postToSocialMedia(page, pages)
        } catch (error) {
          showSnackbarMessage('error', this.$t('alerts.social-media.post-to-social-network.error'))
        }
      }
    },

    async postToSocialMedia (page, pages) {
      let postedSuccessfully = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: POST_TO_SOCIAL_MEDIA,
          variables: {
            input: {
              message: this.content.text,
              imageLink: this.content.image,
              type: KindName[page.type].toUpperCase()
            }
          }
        })
        const { permalink, postId, type: postType } = data?.postToSocialMedia
        this.postData.push({
          permalink,
          postId,
          type: postType
        })
      } catch (err) {
        if (!err.graphQLErrors) {
          this.state = 'connected'
          return
        }
        postedSuccessfully = false
        const mappedError = mapGraphQLErrors(err.graphQLErrors)[0]
        if (mappedError.tokenExpired) {
          this.$emit('reconnectToMeta', { name: KindName.facebookPost })
        }

        showSnackbarMessage('error', this.$t(mappedError.message))
        this.state = 'connected'
      }

      if (!postedSuccessfully) return
      this.state = 'posted'
      this.$emit('savedContent')
      const isLastPostCreated = pages[pages.length - 1].id === page.id
      if (isLastPostCreated) {
        await this.storeSavedContent(pages.map(page => page.type), this.postData)
      }
    },

    async storeSavedContent (types, postData) {
      await this.$apollo.mutate({
        mutation: SAVE_CONTENT,
        variables: {
          input: {
            text: this.content.text,
            imagePath: this.content.image,
            imageSource: this.content.imageSource,
            textKind: this.content.textKind,
            landingPageId: this.content.landingPageId,
            topicId: this.content.topicId,
            postedOn: [...types],
            slug: this.content.slug,
            postData
          }
        }
      })

      this.$gtm.trackEvent({
        event: trackingEvents.CONTENT_CREATOR_SHARED,
        value: {
          type: this.content.textKind,
          topic: this.content.slug
        }
      })
    },

    async addFacebookPageToUser (page) {
      if (page.isActive) return
      try {
        await this.$apollo.mutate({
          mutation: ADD_FACEBOOK_PAGE_TO_USER,
          variables: {
            input: {
              pageId: page.id
            }
          },
          refetchQueries: [{ query: GET_SOCIAL_MEDIA_CONNECTIONS }]
        })
      } catch (err) {
        this.state = 'connected'
      }
    },

    async addLinkedinPageToUser (page) {
      try {
        await this.$apollo.mutate({
          mutation: ADD_LINKEDIN_PAGE_TO_USER,
          variables: {
            input: {
              organizationUrn: page.urn
            }
          },
          refetchQueries: [{ query: GET_SOCIAL_MEDIA_CONNECTIONS }]
        })
      } catch (err) {
        this.state = 'connected'
      }
    },

    redirectRoute () {
      this.$tracking.event('Content Creator', this.$tracking.trackingEvents.CLICKED, 'Postflow-History')
      return this.activeTab === FilterType.CREATOR ? this.$router.push('/content-creator#all') : window.location.reload()
    },

    closeDialog () {
      if (this.isPostedToSocialMedia && this.activeTab !== FilterType.CREATOR) {
        window.location.reload()
      }

      this.$emit('close')
    },
    back () {
      this.$emit('back')
    }
  }
}
</script>

<style scoped>
  .toolbar {
    width: 100%;
  }

  .card-item {
    min-width: 220px;
  }

  .card-item:hover {
    background: rgb(247, 247, 247);
  }

  .pointer {
    cursor: pointer;
  }

  .redirect-route {
    color: var(--orange);
    text-decoration: underline;
  }

</style>
